import Vue from 'vue'
import VueRouter from 'vue-router'
import homePage from '@/views/dashboard/homePage'
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location){
    return originalPush.call(this,location).catch(err => err)
}
const routes = [
    { 
        path: '/', 
        redirect: '/homePage'
    },
    { 
        path: '/homePage', 
        component: () => import('@/views/dashboard/homePage')
    },
    {
        path: '/aboutUs',
        component: () => import('@/views/aboutUs/index')
      },
      {
        path: '/html/ios/privacyPolicy',
        component: () => import('@/views/html/ios/privacyPolicy')
      },
      {
        path: '/html/ios/less18Policy',
        component: () => import('@/views/html/ios/less18Policy')
      },
      {
        path: '/html/ios/payPolicy',
        component: () => import('@/views/html/ios/payPolicy')
      },
      {
        path: '/html/ios/selfmediaPolicy',
        component: () => import('@/views/html/ios/selfmediaPolicy')
      },
      {
        path: '/html/ios/serviceAgreement',
        component: () => import('@/views/html/ios/serviceAgreement')
      },
      {
        path: '/html/ios/share',
        component: () => import('@/views/html/ios/share')
      },
      {
        path: '/html/Android/less18Policy',
        component: () => import('@/views/html/Android/less18Policy')
      },
      {
        path: '/html/Android/member',
        component: () => import('@/views/html/Android/member')
      },
      {
        path: '/html/Android/mpsRule',
        component: () => import('@/views/html/Android/mpsRule')
      },
      {
        path: '/html/Android/payPolicy',
        component: () => import('@/views/html/Android/payPolicy')
      },
      {
        path: '/html/Android/power',
        component: () => import('@/views/html/Android/power')
      },
      {
        path: '/html/Android/privacyPolicy',
        component: () => import('@/views/html/Android/privacyPolicy')
      },
      {
        path: '/html/Android/selfmediaPolicy',
        component: () => import('@/views/html/Android/selfmediaPolicy')
      },
      {
        path: '/html/Android/serviceAgreement',
        component: () => import('@/views/html/Android/serviceAgreement')
      },
      {
        path: '/html/Android/share',
        component: () => import('@/views/html/Android/share')
      },
      // 发布端人脸认证
      {
        path: '/hao/faceRecognition',
        component: () => import('@/views/hao/faceRecognition')
      },
      {
        path: '/hao/result',
        component: () => import('@/views/hao/result')
      },
      // {
      //   path: '/qiushi/payPolicy',
      //   component: () => import('@/views/qiushi/payPolicy')
      // },
      // {
      //   path: '/qiushi/privacyPolicy',
      //   component: () => import('@/views/qiushi/privacyPolicy')
      // },
      // {
      //   path: '/qiushi/savePolicy',
      //   component: () => import('@/views/qiushi/savePolicy')
      // },
      // {
      //   path: '/qiushi/sdkShareList',
      //   component: () => import('@/views/qiushi/sdkShareList')
      // },
      // {
      //   path: '/qiushi/userPolicy',
      //   component: () => import('@/views/qiushi/userPolicy')
      // },
      // {
      //   path: '/qiushi/vipService',
      //   component: () => import('@/views/qiushi/vipService')
      // }
]

const router = new VueRouter({
    routes,
    // mode:'history',
})
export default router